import React, { useState } from "react";
import FinalStyles from "./FinalSubmission.module.css";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";

const FinalSubmission = () => {
  const [title, setTitle] = useState("");
  const [poemType, setPoemType] = useState("regulated");
  const [poem, setPoem] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    if (!title || !poem) {
      setMessage("Please fill in all fields before submitting.");
      return;
    }

    try {
      const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
      const response = await axios.post(
        `${API_URL}/final-submission`,
        {
          title,
          poem_type: poemType,
          poem,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage("Submission successful! Your poem has been submitted for the final Jinshi assessment.");
    } catch (error) {
      console.error("Error submitting poem:", error);
      setMessage("An error occurred while submitting your poem. Please try again.");
    }
  };

  return (
    <div className={FinalStyles.container}>
      <h1 className={FinalStyles.heading}>Final Submission</h1>
      <p className={FinalStyles.text}>
        Here, you are submitting your work for the International Newman Prize for English Jueju Competition!. Have your work evaluated and recognized.
      </p>
      <div className={FinalStyles.form}>
        <label className={FinalStyles.label}>Title of the Poem:</label>
        <input
          type="text"
          className={FinalStyles.input}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title of your poem"
        />
        <label className={FinalStyles.label}>Type of Poem:</label>
        <select
          className={FinalStyles.select}
          value={poemType}
          onChange={(e) => setPoemType(e.target.value)}
        >
          <option value="regulated">Regulated</option>
          <option value="unregulated">Unregulated</option>
        </select>
        <label className={FinalStyles.label}>Poem:</label>
        <textarea
          className={FinalStyles.textarea}
          value={poem}
          onChange={(e) => setPoem(e.target.value)}
          placeholder="Type your poem here..."
        />
        <button className={FinalStyles.submitButton} onClick={handleSubmit}>
          Submit
        </button>
        {message && <p className={FinalStyles.message}>{message}</p>}
      </div>
    </div>
  );
};

export default FinalSubmission;

