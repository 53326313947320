import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Learnstyles from './Learn.module.css';
import Header from './Header'; // Import the Header component
// Importing components for different sections
import MonosyllablePractice from './Syllable';
import Rhyme from './Rhyme';
import BuildUnit from './BuildUnit';
import BuildLine from './BuildLine';
import BuildCouplet from './BuildCouplet';
import BuildPoem from './BuildPoem';
import Theme from './Couplet2';
import Tongsheng from './Tongsheng';
import Emotion from './Emotion';
import Introduction from './Introduction';
import XiucaiIntro from './XiucaiIntro';
import Grammar from './Grammar';
import SemanticResonance from './Semantic';
import PingZe from './PingZe';
import JurenIntro from './JurenIntro';
import Juren from './Juren';
import PingZePoem from './PingZePoem';
import Gongshi from './Gongshi';
import GongshiIntro from './GongshiIntro'; // Import new page
import JinshiIntro from './JinshiIntro';
import FinalSubmission from "./FinalSubmission";
import '@fortawesome/fontawesome-free/css/all.min.css';
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';



const sections = [
  'introduction',
  'monosyllables',
  'buildUnit',
  'buildLine',
  'rhyming',
  'buildCouplet',
  'tongsheng',
  'xiucaiIntro',
  'theme',
  'emotion',
  'buildPoem',
  'jurenIntro',
  'grammar',
  'semanticResonance',
  'pingze',
  'juren',
  'gongshiIntro', // Add new section
  'pingzePoem',
  'gongshi',
  'jinshiIntro',
  'finalSubmission',
];

const unregulatedSections = [
  'introduction',
  'monosyllables',
  'buildUnit',
  'buildLine',
  'rhyming',
  'buildCouplet',
  'buildPoem',
  'tongsheng',
  'xiucaiIntro',
  'theme',
  'emotion',
];

const regulatedSections = [
  'jurenIntro',
  'grammar',
  'semanticResonance',
  'pingze',
  'juren',
  'gongshiIntro', // Add new section
  'pingzePoem',
  'gongshi',
  'jinshiIntro',
];

const Learn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialSection = location.hash ? location.hash.substring(1) : 'introduction';
  const [activeSection, setActiveSection] = useState(initialSection);
  const [mode, setMode] = useState('unregulated');
  const [levelUpdated, setLevelUpdated] = useState(false);
  const [badgeLevel, setBadgeLevel] = useState('guest');

  const level2Ref = useRef(null);
  const level3Ref = useRef(null);
  const level5Ref = useRef(null);
  const switchRef = useRef(null);
  const sidebarRef = useRef(null);
  
  const isSectionLocked = (section) => {
    // Xiucai sections are locked if the badge level is not at least Tongsheng or guest
    if (
      ['xiucaiIntro', 'theme', 'emotion', 'buildPoem'].includes(section) &&
      !['Tongsheng', 'Xiucai', 'Juren', 'Gongshi', 'guest'].includes(badgeLevel)
    ) {
      return true;
    }
    // Juren sections are locked if the badge level is not at least Xiucai or guest
    if (
      ['jurenIntro', 'grammar', 'semanticResonance', 'pingze', 'juren'].includes(section) &&
      !['Xiucai', 'Juren', 'Gongshi', 'guest'].includes(badgeLevel)
    ) {
      return true;
    }
    // Gongshi sections are locked if the badge level is not at least Juren or guest
    if (
      ['gongshiIntro', 'pingzePoem', 'gongshi'].includes(section) &&
      !['Juren', 'Gongshi', 'guest'].includes(badgeLevel)
    ) {
      return true;
    }
    // JinshiIntro is always accessible
    if (section === 'jinshiIntro' || section === 'finalSubmission') {
      return false;
    }
    // Default: Not locked
    return false;
  };
  
  
  
  

  useEffect(() => {
    if (location.hash) {
      setActiveSection(location.hash.substring(1));
    }
  }, [location.hash]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserLevel(token);
    }
  }, []);

  // Watch for section changes and update mode accordingly
  useEffect(() => {
    if (unregulatedSections.includes(activeSection)) {
      setMode('unregulated');
    } else if (regulatedSections.includes(activeSection)) {
      setMode('regulated');
    }
  }, [activeSection]);

  const fetchUserLevel = async (token) => {
    try {
      const response = await fetch(`${API_URL}/user/current-level`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBadgeLevel(data.level || 'guest');
      setLevelUpdated(false);
    } catch (error) {
      console.error('Error fetching user level:', error);
    }
  };
  

  const handleSectionChange = (section) => {
    if (!isSectionLocked(section)) {
      setActiveSection(section);
      navigate(`#${section}`);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  };

  const handleBack = () => {
    const currentIndex = sections.indexOf(activeSection);
    if (currentIndex === 0) {
      navigate('/');
    } else if (currentIndex > 0) {
      handleSectionChange(sections[currentIndex - 1]);
    }
    window.scrollTo(0, 0); // Scroll to the top after navigating back
  };

  const handleNext = () => {
    const currentIndex = sections.indexOf(activeSection);
    if (currentIndex < sections.length - 1) {
      handleSectionChange(sections[currentIndex + 1]);
    }
    window.scrollTo(0, 0); // Scroll to the top after navigating next
  };

  const handleModeChange = (e) => {
    const selectedMode = e.target.value;
    setMode(selectedMode);
    if (selectedMode === 'regulated') {
      // Scroll to the bottom and set Juren Intro as the active section
      sidebarRef.current.scrollTop = sidebarRef.current.scrollHeight; // Scroll to the bottom
      setActiveSection('jurenIntro');
      setTimeout(() => {
        level3Ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    } else {
      // Scroll to the top and set Introduction as the active section
      sidebarRef.current.scrollTop = 0; // Scroll to the top
      setActiveSection('introduction');
      window.scrollTo(0, 0);
    }
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'introduction':
        return <Introduction />;
      case 'monosyllables':
        return <MonosyllablePractice />;
      case 'rhyming':
        return <Rhyme />;
      case 'tongsheng':
        return <Tongsheng onLevelChange={() => setLevelUpdated(true)} />;
      case 'buildUnit':
        return <BuildUnit />;
      case 'buildLine':
        return <BuildLine />;
      case 'buildCouplet':
        return <BuildCouplet />;
      case 'xiucaiIntro':
        return <XiucaiIntro />;
      case 'theme':
        return <Theme />;
      case 'emotion':
        return <Emotion />;
      case 'buildPoem':
        return <BuildPoem onLevelChange={() => setLevelUpdated(true)} />;
      case 'jurenIntro':
        return <JurenIntro />;
      case 'grammar':
        return <Grammar />;
      case 'semanticResonance':
        return <SemanticResonance />;
      case 'pingze':
        return <PingZe />;
      case 'juren':
        return <Juren onLevelChange={() => setLevelUpdated(true)} />;
      case 'gongshiIntro': // Render Gongshi Intro
        return <GongshiIntro />;
      case 'pingzePoem':
        return <PingZePoem />;
      case 'gongshi':
        return <Gongshi />;
      case 'jinshiIntro': // New Jinshi section rendering
        return <JinshiIntro />;
      case 'finalSubmission': // New case
        return <FinalSubmission />;
      default:
        return <p>Select a section from the menu</p>;
    }
  };


  return (
    <div className={Learnstyles.learnContainer}>
      <Header /> {/* Use Header component here */}
      <div className={Learnstyles.content}>
        <aside className={Learnstyles.sidebar} ref={sidebarRef}>
          <div className={Learnstyles.stickySwitchContainer}>
            <select
              className={`${Learnstyles.dropdown} ${Learnstyles.stickySwitch}`}
              value={mode}
              onChange={handleModeChange}
              ref={switchRef}
            >
              <option value="unregulated">Unregulated</option>
              <option value="regulated">Regulated</option>
            </select>
          </div>
          <div className={Learnstyles.levelSection} ref={level2Ref}>
            <h3>Beginning Student (Tongsheng/童生)</h3>
            <button
            className={activeSection === 'introduction' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('introduction')}
          >
            Introduction
          </button>
          <button
            className={activeSection === 'monosyllables' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('monosyllables')}
          >
            Single-Syllables
          </button>
          <button
            className={activeSection === 'buildUnit' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('buildUnit')}
          >
            Build a Unit
          </button>
          <button
            className={activeSection === 'buildLine' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('buildLine')}
          >
            Build a Line
          </button>
          <button
            className={activeSection === 'rhyming' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('rhyming')}
          >
            End-Rhyming
          </button>
          <button
            className={activeSection === 'buildCouplet' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('buildCouplet')}
          >
            Build the First Couplet
          </button>
          <button
            className={activeSection === 'tongsheng' ? Learnstyles.activeButton : Learnstyles.unlockedButton}
            onClick={() => handleSectionChange('tongsheng')}
          >
            Tongsheng Exam
          </button>
          </div>
          <hr className={Learnstyles.divider} />
          <div className={Learnstyles.levelSection} ref={level3Ref}>
          <h3>Budding Talent (Xiucai/秀才)</h3>
          {isSectionLocked('xiucaiIntro') && <i className="fas fa-lock"></i>}
            <button
              className={
                isSectionLocked('xiucaiIntro')
                  ? Learnstyles.lockedButton
                  : activeSection === 'xiucaiIntro'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('xiucaiIntro')}
              disabled={isSectionLocked('xiucaiIntro')}
            >
              Xiucai Introduction
            </button>
            <button
              className={
                isSectionLocked('theme')
                  ? Learnstyles.lockedButton
                  : activeSection === 'theme'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('theme')}
              disabled={isSectionLocked('theme')}
            >
              Theme
            </button>
            <button
              className={
                isSectionLocked('emotion')
                  ? Learnstyles.lockedButton
                  : activeSection === 'emotion'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('emotion')}
              disabled={isSectionLocked('emotion')}
            >
              Emotion
            </button>
            <button
              className={
                isSectionLocked('buildPoem')
                  ? Learnstyles.lockedButton
                  : activeSection === 'buildPoem'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('buildPoem')}
              disabled={isSectionLocked('buildPoem')}
            >
              Xiucai Exam
            </button>

            <hr className={Learnstyles.divider} />
            <h3>Recommended Person (Juren/舉人) </h3>
            {isSectionLocked('jurenIntro') && <i className="fas fa-lock"></i>}
            <button
              className={
                isSectionLocked('jurenIntro')
                  ? Learnstyles.lockedButton
                  : activeSection === 'jurenIntro'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('jurenIntro')}
              disabled={isSectionLocked('jurenIntro')}
            >
              Juren Intro
            </button>
            <button
              className={
                isSectionLocked('grammar')
                  ? Learnstyles.lockedButton
                  : activeSection === 'grammar'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('grammar')}
              disabled={isSectionLocked('grammar')}
            >
              Grammar
            </button>
            <button
              className={
                isSectionLocked('semanticResonance')
                  ? Learnstyles.lockedButton
                  : activeSection === 'semanticResonance'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('semanticResonance')}
              disabled={isSectionLocked('semanticResonance')}
            >
              Semantic Resonance
            </button>
            <button
              className={
                isSectionLocked('pingze')
                  ? Learnstyles.lockedButton
                  : activeSection === 'pingze'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('pingze')}
              disabled={isSectionLocked('pingze')}
            >
              Ping/Ze
            </button>
            <button
              className={
                isSectionLocked('juren')
                  ? Learnstyles.lockedButton
                  : activeSection === 'juren'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('juren')}
              disabled={isSectionLocked('juren')}
            >
              Juren Exam
            </button>

            <hr className={Learnstyles.divider} />
            <div className={Learnstyles.levelSection}></div>
              <h3>Tribute Scholar (Gongshi/貢士)</h3>
              {isSectionLocked('gongshiIntro') && <i className="fas fa-lock"></i>}
              <button
              className={
                isSectionLocked('gongshiIntro')
                  ? Learnstyles.lockedButton
                  : activeSection === 'gongshiIntro'
                  ? Learnstyles.activeButton
                  : Learnstyles.unlockedButton
              }
              onClick={() => handleSectionChange('gongshiIntro')}
              disabled={isSectionLocked('gongshiIntro')}
              >
                Gongshi Intro
              </button>
              <button
                className={
                  isSectionLocked('pingzePoem')
                    ? Learnstyles.lockedButton
                    : activeSection === 'pingzePoem'
                    ? Learnstyles.activeButton
                    : Learnstyles.unlockedButton
                }
                onClick={() => handleSectionChange('pingzePoem')}
                disabled={isSectionLocked('pingzePoem')}
              >
                Ping/Ze for Poem
              </button>
              <button
                className={
                  isSectionLocked('gongshi')
                    ? Learnstyles.lockedButton
                    : activeSection === 'gongshi'
                    ? Learnstyles.activeButton
                    : Learnstyles.unlockedButton
                }
                onClick={() => handleSectionChange('gongshi')}
                disabled={isSectionLocked('gongshi')}
              >
                Gongshi Exam
              </button>
            <hr className={Learnstyles.divider} />
                <h3>Admitted Scholar (Jinshi/進士)</h3>
                <button
                  className={
                    isSectionLocked('jinshiIntro')
                      ? Learnstyles.lockedButton
                      : activeSection === 'jinshiIntro'
                      ? Learnstyles.activeButton
                      : Learnstyles.unlockedButton
                  }
                  onClick={() => handleSectionChange('jinshiIntro')}
                  disabled={isSectionLocked('jinshiIntro')}
                >
                  Jinshi Intro
                </button>
                <button
                  className={
                    isSectionLocked('finalSubmission')
                    ? Learnstyles.lockedButton
                    : activeSection === 'finalSubmission'
                    ? Learnstyles.activeButton
                    : Learnstyles.unlockedButton
                }
                onClick={() => handleSectionChange('finalSubmission')}
                >
                  Final Submission
                </button>
          </div>
        </aside>

        <main className={Learnstyles.mainContent}>
          {renderSection()}
        </main>
      </div>

      <div className={Learnstyles.navigationBar}>
        <button onClick={handleBack}>Back</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default Learn;

