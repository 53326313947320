import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginAdmin } from '../api';
import AdminStyles from './AdminLogin.module.css';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginAdmin({ email, password });
      localStorage.setItem('adminToken', response.access_token); // Ensure token is correctly stored
      navigate('/admin/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={AdminStyles.loginPage}>
      <div className={AdminStyles.loginWrapper}>
        <div className={AdminStyles.formContainer}>
          <h2 className={AdminStyles.formTitle}>Admin Login</h2>
          {error && <p className={AdminStyles.error}>{error}</p>}
          <form className={AdminStyles.form} onSubmit={handleSubmit}>
            <div className={AdminStyles.inputGroup}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={AdminStyles.input}
                required
              />
              <span className={AdminStyles.inputHighlight}></span>
            </div>
            <div className={AdminStyles.inputGroup}>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={AdminStyles.input}
                required
              />
              <span className={AdminStyles.inputHighlight}></span>
            </div>
            <button type="submit" className={AdminStyles.submitBtn}>Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

