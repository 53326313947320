// Assess.js

import React, { useState } from 'react';
import axios from 'axios';
import Assessstyles from './Assess.module.css';
import Header from './Header';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

function Assess() {
  const [submissionType, setSubmissionType] = useState('');
  const [regulationType, setRegulationType] = useState('');
  const [submissionText, setSubmissionText] = useState('');
  const [feedback, setFeedback] = useState('');
  const [pingZeFeedback, setPingZeFeedback] = useState('');
  const [modelAssessment, setModelAssessment] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);

  const handleSubmit = async () => {
    if (!submissionType || !regulationType || !submissionText.trim()) {
      setError('Please complete all steps before submitting.');
      return;
    }

    setLoading(true);
    setFeedback('');
    setPingZeFeedback('');
    setModelAssessment('');
    setError(null);
    setIsValid(null);

    let endpoint = '';
    let level = '';
    let payload = {};

    if (submissionType === 'couplet') {
      endpoint = '/assess-couplet';
      level = regulationType === 'unregulated' ? 'tongsheng' : 'juren';
      payload = {
        couplet: submissionText.trim(),
        user_level: 'guest',
        level: level,
      };
    } else if (submissionType === 'poem') {
      endpoint = '/assess-poem';
      level = regulationType === 'unregulated' ? 'xiucai' : 'gongshi';
      payload = {
        poem: submissionText.trim(),
        user_level: 'guest',
        level: level,
      };
    }

    try {
      const response = await axios.post(`${API_URL}${endpoint}`, payload, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      setIsValid(response.data.is_valid);
      setFeedback(response.data.feedback);
      setPingZeFeedback(response.data.ping_ze_feedback || '');
      setModelAssessment(response.data.model_assessment || '');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while processing your submission.');
    }
    setLoading(false);
  };

  const handleReset = () => {
    setSubmissionText('');
    setSubmissionType('');
    setRegulationType('');
    setFeedback('');
    setPingZeFeedback('');
    setModelAssessment('');
    setError(null);
    setLoading(false);
    setIsValid(null);
  };

  return (
    <div>
      <Header />
      <div className={Assessstyles.assessContainer}>
        <h1 className={Assessstyles.title}>Jueju Poetry Assessment Tool</h1>
        <p className={Assessstyles.instructions}>
          Follow the steps below to submit your couplet or poem for assessment.
        </p>

        <div className={Assessstyles.step}>
          <h4>Select Submission Type</h4>
          <select
            value={submissionType}
            onChange={(e) => setSubmissionType(e.target.value)}
            className={Assessstyles.dropdown}
          >
            <option value="">Choose Submission Type...</option>
            <option value="couplet">Couplet</option>
            <option value="poem">Poem</option>
          </select>
          {!submissionType && <p className={Assessstyles.error}>Submission Type is required.</p>}
        </div>

        {submissionType && (
          <div className={Assessstyles.step}>
            <h4>Select Regulation Type</h4>
            <select
              value={regulationType}
              onChange={(e) => setRegulationType(e.target.value)}
              className={Assessstyles.dropdown}
            >
              <option value="">Choose Regulation Type...</option>
              <option value="unregulated">Unregulated</option>
              <option value="regulated">Regulated</option>
            </select>
            {!regulationType && <p className={Assessstyles.error}>Regulation Type is required.</p>}
          </div>
        )}

        {submissionType && regulationType && (
          <div className={Assessstyles.step}>
            <h3>Enter {submissionType === 'couplet' ? 'Couplet' : 'Poem'}</h3>
            <p className={Assessstyles.textInstructions}>
              Please enter either a couplet (2 lines) or a poem (4 lines). Each line should have either 5 or 7 words.
            </p>
            <textarea
              placeholder={`Enter your ${submissionType} here...`}
              value={submissionText}
              onChange={(e) => setSubmissionText(e.target.value)}
              className={Assessstyles.largeTextArea}
              rows="8"
            />
          </div>
        )}

        <div className={Assessstyles.buttonContainer}>
          <button
            onClick={handleSubmit}
            disabled={!submissionType || !regulationType || loading}
            className={Assessstyles.button}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            onClick={handleReset}
            className={`${Assessstyles.button} ${Assessstyles.resetButton}`}
          >
            Clear
          </button>
        </div>

        {error && <div className={Assessstyles.error}>{error}</div>}
        {feedback && (
          <div
            className={`${Assessstyles.feedback} ${
              isValid === false ? Assessstyles.invalidFeedback : ''
            }`}
          >
            <h2>{isValid === false ? 'Invalid Submission' : 'Feedback'}</h2>
            <p>{feedback}</p>
          </div>
        )}
        {modelAssessment && isValid && (
          <div className={Assessstyles.feedback}>
            <h2>Model Assessment</h2>
            <p>{modelAssessment}</p>
          </div>
        )}
        {pingZeFeedback && (
          <div className={Assessstyles.pingZeFeedback}>
            <h2>Ping/Ze Feedback</h2>
            {pingZeFeedback.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Assess;

