import React, { useState } from 'react';
import styles from './AboutWebsite.module.css';
import Header from './Header'; // Assuming you have a Header component

const AboutWebsite = () => {
  const [expandedSections, setExpandedSections] = useState({
    intro: false,
    learningPaths: false,
    forestPath: false,
    examPath: false,
    examProgress: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div>
      <Header />
      <div className={styles.aboutContainer}>
        <h2 className={styles.heading}>Welcome to the Jueju Path</h2>

        {/* General Introduction - First paragraph static, second one clickable */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>🏞 General Introduction</h3>
          <div className={styles.sectionContent}>
            <p>
              Welcome to the Jueju Path! This website was created by the creator of the English Jueju form and learning system, Jonathan Stalling, to provide a single-stop platform for anyone hoping to learn how to compose one of the world’s most beloved, and certainly its most challenging poetic form, unregulated and regulated jueju “近体绝句/古体绝句”  Stalling created the English Jueju system in the 1990’s at UC Berkeley and has spent two and a half decades refining it and teaching the form online and in-person workshops across the globe ever since. Over the last decade, the Newman Prize for English Jueju, based at the University of Oklahoma, has awarded dozens of $500 prizes to English jueju poets from across five continents in a wide range of age categories ranging from 6 years old to retired adults.
            </p>
            {/* Second paragraph is clickable */}
            <h4 className={styles.sectionTitle} onClick={() => toggleSection('intro')}>
              More about the website {expandedSections.intro ? '▲' : '▼'}
            </h4>
            {expandedSections.intro && (
              <div className={styles.sectionContent}>
                <p>
                As you navigate through the Jueju Path website, you will find a wide range of features to help you learn, review, and master the art of English jueju. It will also provide you with an opportunity to submit your work to the Newman Prize for English Jueju where you will be eligible to earn the title of “Newman Jinshi” and cash prizes. The Jueju Path website is designed with a single starting point in mind which begins by creating an account or logging in. If you are over 18, you can create a unique Jueju identity which will be used to track your progress so that you can stop and start as you wish without losing your badges (which track your progress). If you are under 18, however, you will need to have your parents create an account with their email, or sign in through your teacher, who can sign up as a teacher and open a “ Jueju Path Teacher Dashboard” and assign as many unique student identities/accounts as they wish and track student progress for grading purposes. All final Newman Prize entries will be submitted through individual or teacher accounts at the “Jinshi Level” on the “Examination Path,” or can be submitted directly to <a href>Newmanpoetryaward@ou.edu</a>.                 </p>
              </div>
            )}
          </div>
        </div>

        {/* User Roles Section */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>👥 User Roles</h3>
          <div className={styles.sectionContent}>
            <div className={styles.roleCard}>
              <h4 className={styles.roleTitle}>🌟 Regular Users</h4>
              <p className={styles.roleDescription}>
                Anyone above 18 can sign up as a regular user to create their own Jueju identity. Progress, badges, and submissions are saved, so you can start, stop, and pick up your journey at any time. Simply log in to begin composing and submitting your English Jueju poems!
              </p>
            </div>
            <div className={styles.roleCard}>
              <h4 className={styles.roleTitle}>🏫 Teachers</h4>
              <p className={styles.roleDescription}>
                Teachers can sign up for a personal “Jueju Path Teacher Dashboard” to manage students' progress for grading purposes. Teachers simply need to provide their name, email, name of school, name of class, and a password to get started.
              </p>
              
              <p className={styles.roleNote}>
                No student information will be disclosed to the public, but teachers should follow their school’s privacy rules when assigning student user names (example, one can assign new usernames to students if desired). Just make sure to share the “class name” and their unique “student name” so that they can enter the site.
              </p>

              <a href="/" className={styles.getStartedLink}>
                Get Started
              </a>
            </div>

            <div className={styles.roleCard}>
              <h4 className={styles.roleTitle}>🎓 Students</h4>
              <p className={styles.roleDescription}>
                Students under 18 must be registered by their teacher or parent. Once registered, students can log in using the credentials provided by their teacher and progress through the learning and examination paths to master Jueju poetry.
              </p>
              <p className={styles.roleNote}>
                Final poems submitted through teacher accounts will be reviewed and considered for the annual international Newman Prize for English Jueju.
              </p>
            </div>
            <p>
              After progressing through the four levels of the exams (each with their own sequential rules), students (and adult users) are invited to submit their final jueju for consideration in the annual international Newman Prize for English Jueju hosted at the University of Oklahoma. All entries will be submitted through individual adult user or teacher accounts at the “Jinshi Level” on the “Examination Path,” or can be submitted directly to 
              <a href="mailto:jueju@gmail.com" className={styles.emailLink}>jueju@gmail.com</a> or 
              <a href="mailto:Newmanpoetryaward@ou.edu" className={styles.emailLink}>Newmanpoetryaward@ou.edu</a>.
            </p>

          </div>
        </div>

        {/* Learning Paths */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle} onClick={() => toggleSection('learningPaths')}>
            🛤 Learning Paths {expandedSections.learningPaths ? '▲' : '▼'}
          </h3>
          {expandedSections.learningPaths && (
            <div className={styles.sectionContent}>
              <p>
                Once you have logged in, you will find two distinct learning paths under the “Learn” tab in the navigation bar: 1. The Forest Path, and 2. The Examination Path.
              </p>
            </div>
          )}
        </div>

        {/* Forest Path */}
        <div className={styles.subSection}>
          <h4 className={styles.subTitle} onClick={() => toggleSection('forestPath')}>
            🌲 The Forest Path {expandedSections.forestPath ? '▲' : '▼'}
          </h4>
          {expandedSections.forestPath && (
            <div className={styles.sectionContent}>
              <p>
                The Forest Path introduces the art of Jueju writing as a self-paced ten-day writing retreat that emphasizes Buddhist and Daoist elements of the Jueju path. On this path, we take our time to create handmade books, collect our words from nature, sprout these natural words into poetry Jueju units, and learn how to organize these into cosmologically balanced, inter-resonant forms that emphasize a non-dual approach to harmonizing human intelligence and creativity with the natural world.
              </p>
              <p><strong>This path will be available in late 2025.</strong></p>
            </div>
          )}
        </div>

        {/* Examination Path */}
        <div className={styles.subSection}>
          <h4 className={styles.subTitle} onClick={() => toggleSection('examPath')}>
            📚 The Examination Path {expandedSections.examPath ? '▲' : '▼'}
          </h4>
          {expandedSections.examPath && (
            <div className={styles.sectionContent}>
              <p>
                The Examination Path, introduces the Confucian elements of the jueju form by taking poetry adepts through the five levels of the Chinese Imperial Examination System, the Tongsheng (童生), Xiucai (秀才) Juren (舉人), Gongshi (貢士), and Jinshi (進士) exams, which historically required the mastery of regulated verse including jueju (“近体绝句/格律诗”). In this “exam path,” you will earn badges as you progress through the five exams by mastering the five levels of “cosmological rhyming” which organize human languages according to the ancient concept of “inter-resonance” or “ganying” (感应). These special forms of “rhyming” in regulated jueju (体绝句) include:
              </p>
              <ul className={styles.list}>
                <li>end rhyming</li>
                <li>semantic (meaning) rhyming</li>
                <li>grammatical rhyming</li>
                <li>vowel length (ping/ze) rhyming</li>
                <li>cosmological (nature-human feeling) rhyming</li>
              </ul>
              <p>
              The four primary badges that will be awarded to those who successfully pass the interactive learning materials and a jueju poetry assessment at the end of each Exam stage will enable Jueju students and poets to enter the final level (Jinshi) which will be automatically uploaded to the Newman Prize for English Jueju and will be assessed (and judged) by Jonathan Stalling. 
              </p>
            </div>
          )}
        </div>

        {/* Examination Progress */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle} onClick={() => toggleSection('examProgress')}>
            🚀 Examination Progress {expandedSections.examProgress ? '▲' : '▼'}
          </h3>
          {expandedSections.examProgress && (
            <div className={styles.sectionContent}>
              <p>
              Assessing your progress along the Examination Path: At the start of each exam, you will find introductory guides for each new rule or skill along with examples followed by a series of interactive exercises to reinforce each new jueju rule/skill. At the end of each exam level, you will be prompted to submit an original jueju poem which will be assess by the Jueju Path AI examiner created by and based on the work of Jonathan Stalling, the creator of the English Jueju form and learning system.  The Jueju Examiner will offer you detailed feedback on each level of jueju skills after which successful Jueju poetry poets will progress to the next stage of the examination and to the next set of skills needed to compose an original work of regulated Jueju. 
              </p>
              <br></br>
              <p>
              While not perfect, the Jueju AI Examiner--known in Chinese as your zhī gòng jǔ (知貢舉) which has been fine-tuned to assess your progress and provide you with useful feedback to keep you on track to mastering this legendary artform. If you find the response lacking, do try it again as each reply will be a unique response to your writing. 
              </p>
              <br></br>
              <p>
              Soon, you will also have the opportunity to learn more about the Imperial Examination System in history and earn additional culture badges as well.
              </p>
              <br></br>
              <h3> The Examination Path Exam Stages: </h3>
              <ul className={styles.sectionContent}>
                <p><strong>A.</strong> <strong>Your journey begins by preparing for and taking the Tongsheng (童生, "Beginning Student") Exam!</strong> As a Tongsheng candidate, you will move through a variety of exercises where you will learn and be tested on the skills related to the first level of rhyming “end rhyming” among other key jueju skills (using single-syllable words, building and arranging poetry units into lines and couplets) leading up to creating your own first unregulated couplet!</p><br></br>
                <p><strong>B.</strong> <strong>Once you pass the Tongsheng exam, you will prepare for and take the Xiucai or “Budding Talent” (秀才) exam</strong> as you will master the second level of rhyming, “cosmological rhyming,” where you will discover shared categories of resonance between the natural world (jing 景) and human feeling (qing 情). Here you will learn to compose full unregulated jueju by learning how to follow the four-part thematic jueju line progression ( 起,承,转,合 -- “introduce, deepen, turn, unify) and finally submit your own unregulated jueju for an assessment from the Jueju AI Examiner.</p><br></br>
                <p><strong>C.</strong> <strong>If you pass the Xiucai assessment, you will prepare for and sit the Juren (舉人) exam</strong> where you will learn the next three levels of rhyming (semantic, grammatical, and ping/ze) leading to the creation and assessment of your first regulated couplet!</p><br></br>
                <p><strong>D.</strong> <strong>If you pass the Juren Exam, you will be ready to prepare for and sit the Gongshi (貢士) exam</strong> where you will learn to put everything you learned together and compose your first original regulated jueju!</p><br></br>
                <p><strong>E.</strong> <strong>If your Regulated Jueju passes the Gongshi assessment, you will be ready to sit the final level of the exams the Jinshi (進士) exams,</strong> where you will move beyond the basic jueju writing skills to explore the deeper possibilities of the form to be beautiful, powerful, and moving poetry. At this level, you can learn how to recite your English jueju (朗诵langsong) or learn to chant your poem according the principles of “吟诵yinsong,” an ancient artform in itself. However, to pass this level of the exam path, you will have to submit your poems to be assessed by Jonathan Stalling and other judges as the only Jinshi are those who win a prize or an honorable mention through the international Newman Prize for English Jueju where winners also take home $500 prizes and the coveted title of a Newman Jinshi!</p><br></br>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutWebsite;

