import React, { useState, useEffect } from 'react';
import ConfettiPopup from './ConfettiPopup';
import Tongshengstyles from './Tongsheng.module.css';
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const Tongsheng = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [submittedCouplet, setSubmittedCouplet] = useState('');
  const [assessmentResult, setAssessmentResult] = useState('');
  const [isFiveWordCouplet, setIsFiveWordCouplet] = useState(true);
  const [coupletUnits, setCoupletUnits] = useState(Array(10).fill(''));
  const [modelAssessment, setModelAssessment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showBadgePrompt, setShowBadgePrompt] = useState(false);
  const [currentLevel, setCurrentLevel] = useState('');
  const [submissions, setSubmissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [hasTongshengBadge, setHasTongshengBadge] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');
  const placeholderContent = 'Type here...';

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    if (token) {
      fetchUserLevel(token);
    }
  }, []);

  useEffect(() => {
    if (!coupletUnits[0]) {
      let index = 0;
      const interval = setInterval(() => {
        setPlaceholderText(placeholderContent.substring(0, index));
        index = (index + 1) % (placeholderContent.length + 1);
      }, 200);
      return () => clearInterval(interval);
    }
  }, [coupletUnits]);

  const fetchSubmissions = async (token) => {
    try {
      const response = await fetch(`${API_URL}/submissions/?level=Tongsheng`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (Array.isArray(data)) {
        setSubmissions(data);
      } else {
        setSubmissions([]);
      }
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setSubmissions([]);
    }
  };

  const handleToggle = () => {
    setIsFiveWordCouplet(!isFiveWordCouplet);
    setCoupletUnits(Array(!isFiveWordCouplet ? 10 : 14).fill(''));
  };

  const handleInputChange = (index, value) => {
    const newCoupletUnits = [...coupletUnits];
    newCoupletUnits[index] = value;
    setCoupletUnits(newCoupletUnits);
  };

  const handleReadMore = (submission) => {
    setSelectedSubmission(submission);
  };

  const closeReadMoreModal = () => {
    setSelectedSubmission(null);
  };

  const renderCoupletInputs = () => {
    const units = [];
    for (let i = 0; i < (isFiveWordCouplet ? 5 : 7); i++) {
      units.push(
        <input
          key={i}
          type="text"
          value={coupletUnits[i]}
          onChange={(e) => handleInputChange(i, e.target.value)}
          className={Tongshengstyles.unitBox}
          placeholder={i === 0 ? placeholderText : ''}
          onFocus={() => setPlaceholderText('')}
        />
      );
      if (isFiveWordCouplet && i === 1) {
        units.push(<div key={`gap-${i}`} className={Tongshengstyles.gap}></div>);
      } else if (!isFiveWordCouplet && (i === 1 || i === 3)) {
        units.push(<div key={`gap-${i}`} className={Tongshengstyles.gap}></div>);
      }
    }

    const unitsLine2 = [];
    for (let i = (isFiveWordCouplet ? 5 : 7); i < (isFiveWordCouplet ? 10 : 14); i++) {
      unitsLine2.push(
        <input
          key={i}
          type="text"
          value={coupletUnits[i]}
          onChange={(e) => handleInputChange(i, e.target.value)}
          className={Tongshengstyles.unitBox}
        />
      );
      if (isFiveWordCouplet && i === 6) {
        unitsLine2.push(<div key={`gap-${i}`} className={Tongshengstyles.gap}></div>);
      } else if (!isFiveWordCouplet && (i === 8 || i === 10)) {
        unitsLine2.push(<div key={`gap-${i}`} className={Tongshengstyles.gap}></div>);
      }
    }

    return (
      <>
        <div className={Tongshengstyles.line}>{units}</div>
        <div className={Tongshengstyles.line}>{unitsLine2}</div>
      </>
    );
  };

  const handleSubmission = async (type) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      let couplet;
      if (submittedCouplet) {
        couplet = submittedCouplet;
      } else {
        const line1 = coupletUnits.slice(0, isFiveWordCouplet ? 5 : 7).join(' ');
        const line2 = coupletUnits.slice(isFiveWordCouplet ? 5 : 7).join(' ');
        couplet = `${line1}\n${line2}`;
      }
  
      const endpoint = type === 'manual' ? 'manual-couplet-submission' : 'assess-couplet';
      const payload = {
        couplet: couplet,
        level: "Tongsheng",
        user_level: currentLevel
      };
  
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }), // Include token header only if it exists
      };
  
      const response = await fetch(`${API_URL}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok && data.is_valid) {
        if (type === 'manual') {
          setSuccessMessage(data.message || 'Couplet submitted for manual review.');
        } else {
          setAssessmentResult(data.model_assessment);
          if (isLoggedIn && !hasTongshengBadge) {
            setShowBadgePrompt(true);
          }
        }
      } else {
        setErrorMessage(data.message || 'Failed to submit couplet.');
        setAssessmentResult('');
      }
    } catch (error) {
      console.error('Error submitting couplet:', error);
      setErrorMessage('Error submitting couplet.');
    }
    setIsLoading(false);
  };
  
  

  const fetchUserLevel = async (token) => {
    try {
      const response = await fetch(`${API_URL}/user/current-level`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCurrentLevel(data.level);

      if (data.achievements && data.achievements.Tongsheng) {
        setHasTongshengBadge(true);
      } else {
        setHasTongshengBadge(false);
      }
    } catch (error) {
      console.error('Error fetching user level:', error);
    }
  };

  const renderBadgePrompt = () => (
    isLoggedIn && (
      <div className={Tongshengstyles.badgePrompt}>
        <h4>Did you pass the Tongsheng level?</h4>
        <button className={Tongshengstyles.button} onClick={() => handleBadgeSubmission(true)}>Yes</button>
        <button className={Tongshengstyles.button} onClick={() => handleBadgeSubmission(false)}>No</button>
      </div>
    )
  );
  

  const handleBadgeSubmission = async (passed) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }
  
      if (passed) {
        const response = await fetch(`${API_URL}/user/achievements/tongsheng`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ level: 'Tongsheng' }),
        });
  
        if (response.ok) {
          setShowConfetti(true);
          setCurrentLevel('Tongsheng');
          setHasTongshengBadge(true);
          setTimeout(() => {
            closeConfetti();
          }, 10000);
        } else {
          console.error('Failed to update user level.');
        }
      } else {
        setErrorMessage('Try again and make your couplet better based on the assessment feedback.');
      }
    } catch (error) {
      console.error('Error updating user level:', error);
    }
    setShowBadgePrompt(false);
  };

  const closeConfetti = () => {
    setShowConfetti(false); // Hide the confetti
    window.location.reload(); // Reload the page immediately
  };

  const renderManualAssessmentPrompt = () => (
    isLoggedIn && (
      <div className={Tongshengstyles.manualAssessmentContainer}>
        <p>If you want a manual assessment for more accuracy or if you disagree with the model, you can make a manual submission and receive feedback after a few days or weeks by the admin (check your submissions to see the feedback).</p>
        <button
          onClick={() => handleSubmission('manual')}
          disabled={isLoading}
          className={Tongshengstyles.manualButton}
          title="Your submission will be sent to Jonathan Stalling and his team..."
        >
          Submit for Manual Assessment
        </button>
      </div>
    )
  );
  

  const renderSubmissionContainer = () => (
    <div className={Tongshengstyles.submissionContainer}>
      <h2>Build your own couplet and get assessed!</h2>
      <div className={Tongshengstyles.toggleContainer}>
        <span>{isFiveWordCouplet ? '5-word couplet' : '7-word couplet'}</span>
        <label className={Tongshengstyles.switch}>
          <input type="checkbox" checked={!isFiveWordCouplet} onChange={handleToggle} />
          <span className={`${Tongshengstyles.slider} ${Tongshengstyles.round}`}></span>
        </label>
      </div>
      <p>Type in unit by unit:</p>
      {renderCoupletInputs()}
      <p>OR</p>
      <textarea
        placeholder="Enter your couplet here..."
        value={submittedCouplet}
        onChange={(e) => setSubmittedCouplet(e.target.value)}
        className={Tongshengstyles.textArea}
      />
      <div className={Tongshengstyles.submitButtonsContainer}>
        <button
          onClick={() => handleSubmission('ai')}
          disabled={isLoading}
          className={`${Tongshengstyles.aiButton} ${Tongshengstyles.button}`}
          title="You will receive an instant assessment, but the AI may make mistakes..."
        >
          Submit for AI Assessment
        </button>
      </div>
      {isLoading && <div className={Tongshengstyles.loader}></div>}
      {errorMessage && <div className={Tongshengstyles.errorMessage}>{errorMessage}</div>}
      {successMessage && <div className={Tongshengstyles.successMessage}>{successMessage}</div>}
      {assessmentResult && (
        <div className={Tongshengstyles.assessmentResult}>
          {assessmentResult}
          {!hasTongshengBadge && showBadgePrompt && renderBadgePrompt()}
          {renderManualAssessmentPrompt()}
        </div>
      )}
      {modelAssessment && (
        <div className={Tongshengstyles.modelAssessment}>
          <h4>Model Assessment</h4>
          <p className={Tongshengstyles.generatedText}>{modelAssessment}</p>
        </div>
      )}
    </div>
  );

  const openSubmissionsModal = () => {
    setIsModalOpen(true);
    const token = localStorage.getItem('token');
    if (token) {
      fetchSubmissions(token);
    }
  };

  const renderCoupletWithLineBreaks = (couplet) => {
    const lines = couplet.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderSubmissionsModal = () => (
    <div className={`${Tongshengstyles.modal} ${isModalOpen ? Tongshengstyles.modalOpen : ''}`}>
      <div className={Tongshengstyles.modalContent}>
        <span className={Tongshengstyles.closeButton} onClick={() => setIsModalOpen(false)}>&times;</span>
        <h3>Your Submissions</h3>
        {Array.isArray(submissions) && submissions.length === 0 ? (
          <p>No submissions yet.</p>
        ) : (
          <div className={Tongshengstyles.submissionsContainer}>
            {Array.isArray(submissions) && submissions.map((submission, index) => (
              <div key={index} className={Tongshengstyles.submissionItem}>
                <p><strong>Couplet:</strong> {renderCoupletWithLineBreaks(submission.couplet)}</p>
                <p>
                  <strong>Feedback:</strong>
                  {submission.submission_type === 'manual' && submission.status === 'finished' ? (
                    submission.feedback || 'No feedback available'
                  ) : submission.submission_type === 'manual' && submission.status !== 'finished' ? (
                    <span className={Tongshengstyles.pending}>Pending</span>
                  ) : submission.model_assessment && submission.model_assessment.length > 100 ? (
                    <>
                      {submission.model_assessment.substring(0, 100)}...
                      <span className={Tongshengstyles.readMore} onClick={() => handleReadMore(submission)}> Read More</span>
                    </>
                  ) : (
                    submission.model_assessment || 'No feedback available'
                  )}
                </p>
                <p><strong>Type:</strong> {submission.submission_type}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const renderReadMoreModal = () => (
    selectedSubmission && (
      <div className={`${Tongshengstyles.modal} ${Tongshengstyles.modalOpen}`} style={{ zIndex: 1100 }}>
        <div className={Tongshengstyles.readMoreModalContent}>
          <span className={Tongshengstyles.closeButton} onClick={closeReadMoreModal}>&times;</span>
          <h3>Submission Details</h3>
          <p className={Tongshengstyles.couplet}><strong>Couplet:</strong> {renderCoupletWithLineBreaks(selectedSubmission.couplet)}</p>
          <p className={Tongshengstyles.feedback}><strong>Feedback:</strong> {selectedSubmission.submission_type === 'ai' ? selectedSubmission.model_assessment : selectedSubmission.feedback || 'No feedback available'}</p>
          <p className={Tongshengstyles.type}><strong>Type:</strong> {selectedSubmission.submission_type}</p>
          <p className={Tongshengstyles.status}><strong>Status:</strong> {selectedSubmission.status}</p>
          <p className={Tongshengstyles.submittedAt}><strong>Submitted At:</strong> {new Date(selectedSubmission.submitted_at).toLocaleString()}</p>
        </div>
      </div>
    )
  );

  return (
    <div className={Tongshengstyles.container}>
      {showConfetti && (
      <ConfettiPopup
        message="Congratulations! You now have a Tongsheng badge!"
        onClose={closeConfetti}
      />
      )}
      <button onClick={openSubmissionsModal} className={`${Tongshengstyles.openModalButton} ${Tongshengstyles.button}`}>
        View Previous Submissions
      </button>
      {renderSubmissionContainer()}
      {renderSubmissionsModal()}
      {renderReadMoreModal()}
    </div>
  );
};

export default Tongsheng;

