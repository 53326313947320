import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTrash, FaEye, FaUserPlus, FaInfoCircle,  FaTasks } from 'react-icons/fa';
import Header from './Header';

const TeacherDashboard = () => {
  const [students, setStudents] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [progress, setProgress] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [newStudentName, setNewStudentName] = useState('');
  const [newStudentID, setNewStudentID] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  
  const API_URL = 'https://juejupath.com/api';
  
  const features = [
    {
      icon: "➕", // Add student icon
      title: "Add Students",
      description:
        "Add students to your class and provide them their login details.",
    },
    {
      icon: "📊", // Progress icon
      title: "View Progress",
      description:
        "Track students' progress across levels and understand their learning path.",
    },
    {
      icon: "📜", // Submissions icon
      title: "View Submissions",
      description:
        "Review students' poetry, provide feedback, and help them improve.",
    },
    {
      icon: "🗑️", // Delete student icon
      title: "Delete Students",
      description:
        "Remove students from your class when necessary to keep records clean.",
    },
  ];
  

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${API_URL}/teacher/students/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
      setErrorMessage('Failed to fetch students.');
    }
  };

  const fetchStudentProgress = async (studentId) => {
    try {
      const response = await axios.get(`${API_URL}/teacher/student/${studentId}/progress/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setProgress(response.data.progress);
    } catch (error) {
      console.error('Error fetching student progress:', error);
      setErrorMessage('Failed to fetch student progress.');
    }
  };

  const fetchStudentSubmissions = async (username) => {
    try {
        const response = await axios.get(`${API_URL}/teacher/student/${username}/submissions/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        setSubmissions(response.data.submissions);
        setIsModalOpen(true);
    } catch (error) {
        console.error('Error fetching student submissions:', error);
        setErrorMessage('Failed to fetch submissions.');
    }
};

  
  const handleAddStudent = async () => {
    if (!newStudentName) {
        setErrorMessage('Please enter a username for the student.');
        return;
    }

    try {
        const response = await axios.post(
            `${API_URL}/teacher/add-student/`,
            { username: newStudentName },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        );
        setSuccessMessage(`Student ${newStudentName} added successfully!`);
        setErrorMessage('');
        setNewStudentName('');
        fetchStudents();
    } catch (error) {
        console.error('Error adding student:', error);
        setErrorMessage(
            error.response?.data?.detail || 'Failed to add student. Please try again.'
        );
    }
};


  const handleDeleteStudent = async (studentId) => {
    try {
      await axios.delete(`${API_URL}/teacher/delete-student/${studentId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setSuccessMessage('Student deleted successfully.');
      fetchStudents();
    } catch (error) {
      console.error('Error deleting student:', error);
      setErrorMessage('Failed to delete student. Please try again.');
    }
  };

  const handleViewSubmissions = async (studentId) => {
    try {
        const response = await axios.get(`${API_URL}/teacher/student/${studentId}/submissions/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        setSubmissions(response.data.submissions);
        setSelectedStudent(studentId);
        setIsModalOpen(true);
    } catch (error) {
        console.error('Error fetching submissions:', error);
        if (error.response && error.response.status === 404) {
            setErrorMessage('No submissions found for this student.');
        } else {
            setErrorMessage('Failed to fetch submissions.');
        }
    }
};

  const renderSubmissions = () => {
    if (submissions.length === 0) {
      return <p style={styles.noSubmissionsText}>No submissions found.</p>;
    }

    return submissions.map((submission, index) => (
      <div key={submission._id} style={styles.submissionItem}>
        <p style={styles.submissionTitle}>
          <strong>{submission.couplet ? 'Couplet' : 'Poem'}:</strong>
        </p>
        <p style={styles.coupletText}>
          {submission.couplet
            ? submission.couplet.split('\n').map((line, idx) => (
                <React.Fragment key={idx}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : submission.poem.split('\n').map((line, idx) => (
                <React.Fragment key={idx}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
        </p>
        <p>
          <strong>Submission Level:</strong> {submission.submission_level}
        </p>
        <p>
          <strong>Feedback:</strong>{' '}
          {submission.feedback
            ? submission.model_assessment
            : <span style={styles.pending}>Pending</span>}
        </p>
        <p>
          <strong>Type:</strong> {submission.submission_type}
        </p>
        <p>
          <strong>Status:</strong> {submission.status}
        </p>
        <p>
          <strong>Submitted At:</strong>{' '}
          {new Date(submission.submitted_at).toLocaleString()}
        </p>
      </div>
    ));
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
    setSubmissions([]);
  };

  return (
    <div>
      <Header />
      <div style={styles.container}>
        {/* Introduction Note */}
        <div style={styles.noteSection}>
            <div style={styles.noteIconTitle}>
              <span style={styles.icon}>ℹ️</span>
              <h2 style={styles.noteTitle}>Welcome to the Teacher Dashboard</h2>
            </div>
            <p style={styles.noteDescription}>
              Manage your class effectively by adding students, tracking progress, reviewing submissions, and keeping records up-to-date. 
            </p>
          </div>


        {/* Features Section */}
        <div style={styles.featuresSection}>
          {features.map((feature, index) => (
            <div key={index} style={styles.featureCard}>
              <div style={styles.featureIcon}>{feature.icon}</div>
              <h3 style={styles.featureTitle}>{feature.title}</h3>
              <p style={styles.featureDescription}>{feature.description}</p>
            </div>
          ))}
        </div>


        {/* Learn More Section */}
        <div style={styles.learnMoreSection}>
          <button
            style={styles.learnMoreButton}
            onClick={() => setShowLearnMore(!showLearnMore)}
          >
            {showLearnMore ? "Hide Details" : "Learn More"}
          </button>

          {showLearnMore && (
            <div style={styles.learnMoreContent}>
              <h3>Step-by-Step Guide</h3>
              <ol>
                <li>
                  <strong>Add Students:</strong> Use the "Add Student" option to enter usernames. Share the username with students so they can log in.
                </li>
                <li>
                  <strong>Track Progress:</strong> View each student's progress as they advance through the levels.
                </li>
                <li>
                  <strong>View Submissions:</strong> Check their poetry submissions and provide feedback.
                </li>
                <li>
                  <strong>Delete Students:</strong> Use the delete option to remove inactive students from the system.
                </li>
              </ol>
              <p>
                If you need further assistance, please contact the support team.
              </p>
            </div>
          )}
        </div>
      <div style={styles.container}>
        <div style={styles.header}>
          <h1 style={styles.title}>Teacher Dashboard</h1>
          <p style={styles.studentCount}>Total Students: {students.length}</p>
        </div>

        <div style={styles.mainContent}>
          <div style={styles.sidebar}>
            <h2 style={styles.sidebarTitle}>Manage Students</h2>
            <div style={styles.addStudentContainer}>
            <input
              type="text"
              placeholder="Student Username"
              value={newStudentName}
              onChange={(e) => setNewStudentName(e.target.value)}
              style={styles.input}
          />
          <button onClick={handleAddStudent} style={styles.addButton}>
              <FaUserPlus /> Add Student
          </button>
            </div>
            {errorMessage && <p style={{ ...styles.message, ...styles.error }}>{errorMessage}</p>}
            {successMessage && <p style={{ ...styles.message, ...styles.success }}>{successMessage}</p>}
          </div>

          <div style={styles.studentListContainer}>
            {students.map((student) => (
              <div style={styles.studentCard}>
              <div style={styles.studentInfo}>
                  <h3 style={styles.studentName}>{student.username}</h3>
                  <p style={styles.studentDetails}>Group: {student.group_name}</p>
              </div>
              <div style={styles.progressSection}>
                  <h4>Progress</h4>
                  <div style={styles.progressRoute}>
                      {['Tongsheng', 'Xiucai', 'Juren', 'Gongshi'].map((level, index) => (
                          <React.Fragment key={index}>
                              <div
                                  style={{
                                      ...styles.dot,
                                      backgroundColor: student.progress?.[level] ? '#4caf50' : '#e0e0e0',
                                  }}
                              />
                              {index < 3 && (
                              <div
                                style={{
                                  ...styles.line,
                                  backgroundColor: student.progress?.[level] && student.progress?.[Object.keys(student.progress)[index + 1]]
                                    ? '#4caf50' // Line color if both current and next level are done
                                    : '#e0e0e0', // Default line color
                                }}
                              />
                              )}    
                          </React.Fragment>
                      ))}
                  </div>
                  <div style={styles.progressLabels}>
                      {['Tongsheng', 'Xiucai', 'Juren', 'Gongshi'].map((level, index) => (
                          <p key={index} style={styles.levelLabel}>{level}</p>
                      ))}
                  </div>
              </div>
              <div style={styles.studentActions}>
                  <button
                      style={styles.viewButton}
                      onClick={() => handleViewSubmissions(student.username)}
                  >
                      <FaEye /> View Submissions
                  </button>
                  <button onClick={() => handleDeleteStudent(student.username)} style={styles.deleteButton}>
                      <FaTrash /> Delete
                  </button>
              </div>
          </div>
          
            ))}
          </div>
        </div>
        {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <span style={styles.closeButton} onClick={handleCloseModal}>&times;</span>
            <h2 style={styles.title}>Submissions for Student: {selectedStudent}</h2>

            {submissions.length > 0 ? (
              <div style={styles.submissionsContainer}>
                {renderSubmissions()}
              </div>
            ) : (
              <p style={styles.noSubmissionsText}>No submissions found.</p>
            )}


            <button onClick={handleCloseModal} style={styles.closeModalButton}>
              Close
            </button>
          </div>
        </div>
      )}


      </div>
    </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#F5F6FA',
    padding: '40px',
    maxWidth: '1400px',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#2C3E50', // Deep Blue
    color: '#FFFFFF', // White Text
    borderRadius: '8px',
    marginBottom: '30px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: '2rem',
  },
  studentCount: {
    fontSize: '1.2rem',
    color:'#ffffff',
  },
  mainContent: {
    display: 'flex',
  },
  sidebar: {
    width: '300px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginRight: '20px',
  },
  sidebarTitle: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '20px',
  },
  addStudentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    fontSize: '1rem',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    outline: 'none',
  },
  addButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  },
  message: {
    fontSize: '1rem',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '6px',
    marginTop: '10px',
  },
  error: {
    backgroundColor: '#ffcccc',
    color: '#cc0000',
  },
  success: {
    backgroundColor: '#ccffcc',
    color: '#009900',
  },
  studentListContainer: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
  },
  studentCard: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  studentInfo: {
    marginBottom: '15px',
  },
  studentName: {
    fontSize: '1.5rem',
    color: '#333',
  },
  studentDetails: {
    fontSize: '1rem',
    color: '#777',
  },
  progressSection: {
    marginTop: '10px',
    width: '100%',
    textAlign: 'center',
  },
  progressRoute: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
    width: '100%',
  },
  dot: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#e0e0e0',
    transition: 'background-color 0.3s ease',
  },
  line: {
    height: '4px',
    flex: 1,
    margin: '0 8px',
    transition: 'background-color 0.3s ease',
  },
  progressLabels: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '5px',
  },
  levelLabel: {
    fontSize: '0.8rem',
    color: '#333',
  },
  studentActions: {
    display: 'flex',
    gap: '10px',
    marginTop: '20px',
  },
  viewButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    color: '#ffffff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '600px',
    textAlign: 'center',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
  },
  submissionsList: {
    listStyleType: 'none',
    padding: 0,
    marginTop: '10px',
  },
  submissionItem: {
    backgroundColor: '#f8f8f8',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '6px',
    textAlign: 'left',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    backgroundColor: '#4a4e69',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: '20px',
  },
  submissionsContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    marginTop: '20px',
  },
  
  submissionItem: {
    backgroundColor: '#f0f8ff',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
  
  submissionItemHover: {
    backgroundColor: '#e6f1ff',
    transform: 'translateY(-3px)',
  },
  
  submissionTitle: {
    fontSize: '1.2em',
    color: '#4a403a',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  
  coupletText: {
    backgroundColor: '#ffffff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.05)',
    marginBottom: '10px',
  },
  
  pending: {
    color: '#ff9800',
  },
  
  noSubmissionsText: {
    fontSize: '1.2em',
    color: '#4a403a',
    textAlign: 'center',
  },
  coupletText: {
    backgroundColor: '#ffffff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.05)',
    marginBottom: '10px',
    whiteSpace: 'pre-wrap', // Ensures line breaks are respected
  },  
  
  closeModalButton: {
    display: 'block',
    margin: '20px auto',
    padding: '10px 20px',
    backgroundColor: '#8c7266',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '1em',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  },
  
  closeModalButtonHover: {
    backgroundColor: '#6b5248',
    transform: 'translateY(-3px)',
  },
  noteSection: {
    backgroundColor: '#FFFFFF',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
  },
  noteIconTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '10px',
  },
  icon: {
    fontSize: '1.5rem',
    color: '#27AE60', // Soft Green
  },
  noteTitle: {
    fontSize: '1.5rem',
    color: '#2C3E50', // Deep Blue
    margin: 0,
  },
  noteDescription: {
    fontSize: '1rem',
    color: '#34495E', // Dark Gray
    margin: '5px 0 0 0',
    lineHeight: '1.5',
  },

  featuresSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '20px',
    marginBottom: '30px',
    padding: '10px',
  },
  featureCard: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  featureCardHover: {
    transform: 'scale(1.03)',
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
  },
  featureIcon: {
    fontSize: '2rem',
    marginBottom: '10px',
    color: '#2D87E6', // Modern blue for icons
  },
  featureTitle: {
    fontSize: '1.2rem',
    color: '#2C3E50', // Deep Blue
    marginBottom: '8px',
  },
  featureDescription: {
    fontSize: '0.9rem',
    color: '#7F8C8D', // Soft Gray
    lineHeight: '1.5',
  },
  learnMoreSection: {
    textAlign: 'center',
    marginTop: '30px',
  },
  learnMoreButton: {
    backgroundColor: '#3498DB',
    color: '#ffffff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  learnMoreContent: {
    backgroundColor: '#f0f8ff',
    padding: '20px',
    borderRadius: '8px',
    marginTop: '20px',
    textAlign: 'left',
    border: '1px solid #007bff',
  },
};

export default TeacherDashboard;

