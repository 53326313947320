import React, { useRef } from 'react';
import Jinshistyles from './JinshiIntro.module.css';
import PingZePoemstyles from './PingZePoem.module.css'; // Import styles for patterns
import { FaVolumeUp } from 'react-icons/fa'; // Import speaker icon
import langsongAudio from './Lansong.m4a'; // Import Langsong audio file
import yinsongAudio from './Yinsong.m4a';

const JinshiIntro = () => {
  const langsongAudioRef = useRef(null);
  const yinsongAudioRef = useRef(null);

  const playAudio = (audioRef) => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const sevenPingStartPattern = {
    line1: ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
    line2: ["ze", "ze", "ping", "ping", "ze", "ze", "ping"],
    line3: ["ze", "ze", "ping", "ping", "ping", "ze", "ze"],
    line4: ["ping", "ping", "ze", "ze", "ze", "ping", "ping"],
  };

  const examplePoem = [
    "Carved stones    swift brook    brisk wind blows",
    "Flint path       birds song     thick grass grows",
    "Cut bait         old hands      aged wrist casts",
    "Sun fades        soft light     fast mind slows",
  ];

  const renderLineWithSpacing = (line) => {
    const units = [
      line.slice(0, 2), // First 2 words (2-word unit)
      line.slice(2, 4), // Next 2 words (2-word unit)
      line.slice(4),    // Last 3 words (3-word unit)
    ];

    return (
      <div className={PingZePoemstyles.unitRow}>
        {units.map((unit, index) => (
          <div
            key={index}
            className={
              index === 2
                ? PingZePoemstyles.threeWordUnit
                : PingZePoemstyles.twoWordUnit
            }
          >
            {unit.map((type, i) => (
              <div className={PingZePoemstyles.unitGroup} key={i}>
                <span
                  className={`${PingZePoemstyles.circle} ${
                    type === "ze"
                      ? PingZePoemstyles.filledCircle
                      : PingZePoemstyles.emptyCircle
                  }`}
                ></span>
                <span className={PingZePoemstyles[type]}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderPattern = (pattern) => {
    return (
      <div className={PingZePoemstyles.tonalPatternsContainer}>
        {Object.values(pattern).map((line, index) => (
          <div key={index} className={PingZePoemstyles.tonalPatternUnitContainer}>
            {renderLineWithSpacing(line)}
          </div>
        ))}
      </div>
    );
  };

  const renderPoem = (poem) => {
    return (
      <div className={Jinshistyles.poemContainer}>
        {poem.map((line, index) => (
          <p key={index} className={Jinshistyles.poemLine}>
            {line}
          </p>
        ))}
      </div>
    );
  };

  return (
    <div className={Jinshistyles.jinshiIntroContainer}>
      <h1 className={Jinshistyles.heading}>Level 5: Jinshi</h1>
      <p className={Jinshistyles.text}>
        Congratulations, you have passed the Gongshi level, and should be ready
        to submit your poem to be judged in the international Newman Prize for
        English Jueju, where you can be awarded the highest honor of becoming a
        jinshi!
      </p>
      <p className={Jinshistyles.text}>
        To submit your final jueju (regulated or unregulated), upload it in the
        next page that says Final Submission.
      </p>
      <p className={Jinshistyles.text}>
        Those selected to be a winner, will receive a certificate naming them a
        “Newman Jinshi” in their country of residence and age group, and a
        check of $500.00. To receive these honors, however, winners will need
        to submit a video of them reading their poem for the Newman Prize
        Ceremony.
      </p>
      <h2 className={Jinshistyles.subheading}>Exploring the Next Level of 韻</h2>

      <blockquote className={Jinshistyles.quote}>
        <p>Poems can be recited in two ways.</p>
        <p>
          The first is called “langsong” which refers to a form of spoken (not
          sung) recitation where the speaker reads each poetry unit (those of
          two and those of three words) individually with pauses between them.
          The longest pause is after each line. By doing so, the jueju rhythm
          is more clearly expressed which is different from any other form of
          English poetry which relies on an alternation between stressed and
          unstressed syllables. In unregulated jueju all syllables are stressed
          similarly.
        </p>
      </blockquote>

      <blockquote className={Jinshistyles.quote}>
        <p>
          When reciting a regulated jueju in the Langsong format, speakers
          should attempt to lengthen or clip the second word of each poetry
          unit according to their Ping or Ze quality. This creates a rhythm
          that others can clearly hear in accordance with the Ping or Ze start
          patterns.
        </p>
      </blockquote>

      <p className={Jinshistyles.text}>Here are some examples of reciting poetry in this way.</p>

      <h3 className={Jinshistyles.subheading}>Seven-Word Ping Start Example</h3>
      <p className={Jinshistyles.text}>
        Below is an example of a seven-word Jueju poem following a Ping Start
        pattern:
      </p>

      {/* Render the Ping/Ze Pattern */}
      {renderPattern(sevenPingStartPattern)}

      <br />
      {/* Render the Example Poem */}
      <h4 className={Jinshistyles.subheading}>Example Poem:</h4>
      {renderPoem(examplePoem)}

      {/* Audio Section */}
      <div className={Jinshistyles.audioContainer}>
        <p className={Jinshistyles.text}>Click to listen to the recitations:</p>
        <div className={Jinshistyles.audioSection}>
          <div className={Jinshistyles.audioItem}>
            <span className={Jinshistyles.audioLabel}>Langsong Recitation:</span>
            <audio ref={langsongAudioRef} src={langsongAudio} controls></audio>
          </div>
          <div className={Jinshistyles.audioItem}>
            <span className={Jinshistyles.audioLabel}>Yinsong Recitation:</span>
            <audio ref={yinsongAudioRef} src={yinsongAudio} controls></audio>
          </div>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className={Jinshistyles.additionalInfo}>
        <p className={Jinshistyles.text}>
          For more on how to Chant poems in the “Yinsong” tradition see the
          following:
        </p>
        <a
          className={Jinshistyles.link}
          href="https://www.youtube.com/watch?v=A5Q8bYZgCn4"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.youtube.com/watch?v=A5Q8bYZgCn4
        </a>
      </div>
    </div>
  );
};

export default JinshiIntro;

