import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti'; // Install with `npm install react-confetti`
import styles from './ConfettiPopup.module.css';

const ConfettiPopup = ({ message, onClose }) => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
      onClose();
    }, 5000); // Confetti animation duration: 5 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={styles.popupContainer}>
      {showConfetti && <Confetti />}
      <div className={styles.messageContainer}>
        <button className={styles.closeButton} onClick={onClose}>
          Close
        </button>
        <h2>🎉 {message} 🎉</h2>
      </div>
    </div>
  );
};

export default ConfettiPopup;

