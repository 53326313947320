import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import modalStyles from './Modal.module.css';
import PasswordModal from './PasswordModal';
const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

const AccountModal = ({ closeModal }) => {
  const [userType, setUserType] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [studentId, setStudentId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(''); // Feedback message

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${API_URL}/user/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setUserType(data.user_type);
        setName(data.name);

        if (data.user_type === 'student') {
          setStudentId(data.student_id);
          setGroupName(data.group_name);
          setTeacherName(data.teacher_name);
        } else {
          setEmail(data.email);
        }
      } catch (error) {
        setFeedbackMessage('Error fetching user profile');
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handlePasswordChange = () => {
    setShowPasswordModal(true);
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.modalContent}>
        <span className={modalStyles.closeButton} onClick={closeModal}>&times;</span>
        <h2 className={modalStyles.title}>Account Details</h2>

        <div className={modalStyles.fieldContainer}>
          <label>Name: </label>
          <span className={modalStyles.fieldValue}>{name}</span>
        </div>

        {userType === 'student' ? (
          <>
            <div className={modalStyles.fieldContainer}>
              <label>Group Name: </label>
              <span className={modalStyles.fieldValue}>{groupName}</span>
            </div>
            <div className={modalStyles.fieldContainer}>
              <label>Teacher Name: </label>
              <span className={modalStyles.fieldValue}>{teacherName}</span>
            </div>
          </>
        ) : (
            <div className={modalStyles.fieldContainer}>
              <label>Email: </label>
              <span className={modalStyles.fieldValue}>{email}</span>
            </div>

        )} 

          {userType !== 'student' && (
            <div className={modalStyles.fieldContainer}>
              <label>Password: </label>
              <span className={modalStyles.fieldValue}>********</span>
              <FaEdit
                className={modalStyles.editIcon}
                onClick={handlePasswordChange}
              />
            </div>
          )}


        {feedbackMessage && (
          <p className={modalStyles.feedbackMessage}>{feedbackMessage}</p>
        )}
      </div>

      {showPasswordModal && <PasswordModal closeModal={() => setShowPasswordModal(false)} />}
    </div>
  );
};

export default AccountModal;

